body,
html {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  background: #f5f6ff;
}

.loadingAnimation {
  -webkit-animation: action 1s infinite alternate;
  animation: action 1s infinite alternate;
}

@-webkit-keyframes action {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-24px);
  }
}

@keyframes action {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-24px);
  }
}

.AppMenuItem {
  position: relative;
}
.AppMenuItem.active::before {
  content: url('../images/sidebar/roundtop.png');
  position: absolute;
  height: 16px;
  width: 16px;
  position: absolute;
  right: 0;
  top: -15px;
}
.AppMenuItem.active::after {
  content: url('../images/sidebar/roundbottom.png');
  position: absolute;
  height: 16px;
  width: 16px;
  position: absolute;
  right: 0;
  bottom: -15px;
}
/* .AppMenuItem.active::after {
  content: '';
  position: absolute;
  height: 24px;
  width: 24px;
  background: #393a96;
  border-radius: 0 0 24px 0;
  position: absolute;
  right: 0;
  top: -24px;
  box-shadow: 0px 0px 0 #fff;
} */
